import React, { useEffect, useState, useMemo, useRef } from 'react'

import FilterButton from '../../common/filters/filterButton/filterButton'
import * as searchStyles from '../../common/search/search.module.scss'
import * as actionsPageStyles from './actionsPage.module.scss'

import { ACTION_CONSTANTS } from './actionConstants'
import { Actions, PAGINATION } from '../../../constants/tables'
import ColumnsButton from '../../common/filters/columnsButton/columnsButton'
import {
   isAdminRole,
   isCompanyRole,
   isEmployeeRole,
} from '../../../common/utils/roles'
import {
   useItemHistory,
   useItemNotes,
   useItemsView,
   useProfile,
   useSettings,
   useTableOptions,
} from '../../../hooks'
import { Filters as FiltersObject } from '../../../hooks/useFilters/useFilters.type'

import { useMediaQuery } from 'react-responsive'
import useActionItems from '../../../hooks/useActionItems/useActionItems'
import { Col, Row } from 'react-grid-system'
import { Item as ReorderItem } from '../../common/itemReorder/itemReorder.type'
import { TableWrapper } from '../../common/table/TableWrapper'

import {
   formatLocaleDate,
   formatUsDate,
   removeMinYear,
} from '../../../common/utils/functions'
import { renderOrderedTableColumns } from '../../../common/utils/table'
import { generateExcelData, exportToExcel } from './actionsHelpers'
import { formatPrice } from '../../../constants/global'
import {
   LoadingOverlay,
   Image,
   Modal,
   CrudItems,
   Button,
   PageHeader,
   RadioButton,
   RadioButtonContainer,
   Search,
   Svg,
} from '../../common'
import { Item, ToolStatus, ToolTypes } from '../../../types'
import AddAllToCartButton from '../../actionsWorkflow/addAllToCartButton'
import { AddToCartButton } from '../../actionsWorkflow/addToCartButton'
import { ItemReorderPanel } from '../../itemReorder/itemReorderPanel'
import CartButton from '../../actionsWorkflow/cartButton'
import { QuantityModal } from '../../actionsWorkflow/quantityModal'
import CrudActionCartButtons from '../../actionsWorkflow/crudActionCartButtons'
import { CheckoutModal } from '../../actionsWorkflow/checkoutModal'
import { ActionsFilterPanel } from '../../actionsWorkflow/actionsFilterPanel'
import { TableColumnCellWrapper } from './../../common/table/tableColumnCellWrapper'
import { CrudItemsParentView } from '../../../enums/CrudItemsEnums'

const { quickFilterOptions, quickFilterEmployeeOptions, defaultColumnOrder } =
   ACTION_CONSTANTS

export const ActionsPage = () => {
   const { settings, isLoading: isSettingsLoading } = useSettings()
   const { profile } = useProfile()

   const {
      actionItems,
      actionItemsIsLoading,
      actionItemsIsRefetching,
      setToolSearchOptions,
      actionItemsCount,
   } = useActionItems()

   const {
      isLoading: tableColumnOptionsLoading,
      tableColumnOptions: actionTableOptions,
      update: saveTableColumnOptions,
   } = useTableOptions('actionsTable')

   const {
      data: listViewData,
      getItemView,
      isLoading: isListLoading,
      isSuccess: isListSuccess,
   } = useItemsView()

   const {
      data: itemNotesData,
      itemNotesList: getItemsNotes,
      refetch: refetchNoteData,
   } = useItemNotes()

   const { data: itemHistoryData, itemHistoryList } = useItemHistory()

   const isLoadingData =
      isSettingsLoading ||
      actionItemsIsLoading ||
      actionItemsIsRefetching ||
      tableColumnOptionsLoading

   const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

   const userRoles = profile?.Roles || []
   const isEmployeeUser = isEmployeeRole(userRoles)
   const isAdminCompanyUser = isAdminRole(userRoles) || isCompanyRole(userRoles)

   // main page reset
   const [isPageReset, setIsPageReset] = useState<boolean>(false)
   // quick filter tabs
   const quickFilterTabs = !isEmployeeUser
      ? quickFilterOptions
      : quickFilterEmployeeOptions
   const [selectedActionQuickFilter, setSelectedActionQuickFilter] =
      useState<any>(quickFilterTabs[0])
   // filters
   const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false)
   const [filterValues, setFilterValues] = useState<any>({
      toolStatus: selectedActionQuickFilter?.status,
   })

   // search
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const [isSearchMode, setIsSearchMode] = useState<boolean>(false)

   // pagination

   // const [currentSkip, setCurrentSkip] = useState<number | null>(
   //    PAGINATION.skip
   // )
   const [currentTake, setCurrentTake] = useState<number | null>(
      PAGINATION.take
   )

   const [pageSized, setPageSized] = useState(PAGINATION.pageSized)
   const [isPaginationReset, setIsPaginationReset] = useState<boolean>(false)

   // cart pagination
   const [currentCartSkip, setCurrentCartSkip] = useState<number | null>(
      PAGINATION.skip
   )
   const [currentCartTake, setCurrentCartTake] = useState<number | null>(
      PAGINATION.take
   )
   const [pagedCartData, setPagedCartData] = useState<Item[]>(actionItems)

   // selection
   const [selectedItemsForCart, setSelectedItemsForCart] = useState<Item[]>([])
   const [selectedItemIdsInCart, setSelectedItemIdsInCart] = useState<number[]>(
      []
   )
   const [currentSelectedItem, setCurrentSelectedItem] = useState<Item>(null)
   const [currentSelectedItemToRemove, setCurrentSelectedItemToRemove] =
      useState<Item>(null)
   const [itemsForQuantityModal, setItemsForQuantityModal] = useState<Item[]>(
      []
   )
   const [isEditQuantity, setIsEditQuantity] = useState<boolean>(false)

   const [isQuantityModalOpen, setIsQuantityModalOpen] =
      useState<boolean>(false)

   // columns and column settings
   const [isColumnSettingsOpen, setIsColumnSettingsOpen] =
      useState<boolean>(false)
   const [tmpColumnList, setTmpColumnList] = useState<string[]>([])

   // checkout options
   const [isCheckoutModalOpen, setIsCheckoutModalOpen] =
      useState<boolean>(false)

   // card form view
   const [itemForm, setItemForm] = useState({})

   const [isItemDetailsVisible, setIsItemDetailsVisible] =
      useState<boolean>(false)
   const [isItemsDetailsVisibleCartView, setIsItemDetailsVisibleCartView] =
      useState<boolean>(false)
   const [itemViewStatus, setItemViewStatus] = useState(0)
   const [itemImages, setItemImages] = useState([])

   const isTableCellDisabled = (id: number, firstIndexHeader: string) => {
      if (firstIndexHeader === 'Action') return false
      return selectedItemIdsInCart.includes(id)
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Add',
            accessor: 'localUniqueId',
            id: 'CTA',
            width: 60,
            minWidth: 60,
            align: 'center',
            Cell: (cell) => (
               <AddToCartButton
                  cell={cell}
                  action={(cell) => handleAddOrRemoveFromCart(cell.value)}
                  isKitItem={cell.row.original.IsKitItem}
                  isItemInCart={selectedItemIdsInCart.includes(
                     cell.row.original.localUniqueId
                  )}
               />
            ),
         },
         {
            Header: 'Pic',
            accessor: 'PictureURL',
            id: 'PictureURL',
            allowBlank: true,
            Cell: (cell) => (
               <Image
                  url={cell.row.original.PictureURL}
                  alt={cell.row.original.PictureURL}
                  defaultImagePadding={3}
                  width={25}
               />
            ),
            maxWidth: 65,
         },
         {
            Header: 'Item Name',
            accessor: 'Title',
            id: 'Title',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Title ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Type',
            accessor: 'ToolType',
            id: 'ToolType',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={ToolTypes[row?.original?.ToolType] ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Assigned to Kit',
            accessor: (row) => row.AssignedToKitName ?? '-',
            id: 'AssignedToKitName',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => {
               return (
                  <TableColumnCellWrapper
                     content={row.original.AssignedToKitName ?? '-'}
                     disabled={isTableCellDisabled(
                        row.original.localUniqueId,
                        row.cells[0].column.Header
                     )}
                  />
               )
            },
         },
         {
            Header: 'Total Qty',
            id: 'Quantity',
            accessor: 'TotalQuantityForGrid',
            width: 65,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.TotalQuantityForGrid ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Reorder Level',
            accessor: 'ReorderLevelQuantity',
            id: 'ReorderLevelQuantity',
            width: 65,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.ReorderLevelQuantity ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Status Qty',
            accessor: 'StatusQuantity',
            id: 'StatusQuantity',
            width: 65,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => {
               return (
                  <TableColumnCellWrapper
                     content={row.original.StatusQuantity ?? '-'}
                     disabled={isTableCellDisabled(
                        row.original.localUniqueId,
                        row.cells[0].column.Header
                     )}
                  />
               )
            },
         },
         {
            Header: 'Status',
            accessor: 'StatusDesc',
            id: 'StatusDesc',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.StatusDesc ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Loaned/Pending To',
            accessor: (row) => row.AssignedToUser ?? '-',
            id: 'AssignedToUser',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.AssignedToUser ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Item Detail',
            accessor: (row) => row.Description ?? '-',
            id: 'Description',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Description ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Manufacturer',
            accessor: 'Manufacturer',
            id: 'Manufacturer',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Manufacturer ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Model No.',
            accessor: (row) => row.ModelNumber ?? '-',
            id: 'ModelNumber',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.ModelNumber ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Serial No.',
            accessor: (row) => row.SerialNumber ?? '-',
            id: 'SerialNumber',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.SerialNumber ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Barcode',
            accessor: (row) => row.Barcode ?? '-',
            id: 'Barcode',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Barcode ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Category',
            accessor: 'Category',
            id: 'Category',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Category ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Purchase Date',
            accessor: (row) => {
               return removeMinYear(formatUsDate(row.PurchaseDate))
            },
            width: 110,
            minWidth: 20,
            id: 'PurchaseDate',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(formatUsDate(row.original.PurchaseDate)) ??
                     '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Purchase Price',
            accessor: (row) => formatPrice(row.PurchasePrice || 0),
            id: 'PurchasePrice',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatPrice(row.original.PurchasePrice || 0)}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Total Value',
            accessor: (row) => formatPrice(row.TotalValue),
            id: 'TotalValue',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatPrice(row.original.TotalValue || 0)}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Vendor',
            accessor: (row) => row.Vendor ?? '-',
            id: 'Vendor',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Vendor ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Warranty Date',
            accessor: (row) => {
               if (isNaN(parseInt(row.WarrantyDate))) {
                  return '-'
               }
               return removeMinYear(formatUsDate(row.WarrantyDate))
            },
            id: 'WarrantyDate',
            width: 110,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(formatUsDate(row.original.WarrantyDate)) ??
                     '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Rental Item',
            accessor: (row: { IsRentalTool: boolean }) =>
               row.IsRentalTool ? 'rented' : 'not rented',
            id: 'RentalItem',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.IsRentalTool ? 'rented' : 'not rented'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Rental Start Date',
            accessor: (row) => {
               return removeMinYear(formatUsDate(row.RentalDate))
            },
            id: 'RentalDate',
            width: 110,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(formatUsDate(row.original.RentalDate)) ?? '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Rental End Date',
            accessor: (row) =>
               removeMinYear(formatUsDate(row.RentalReturnDate)) ?? '-',
            id: 'RentalReturnDate',
            width: 110,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(
                        formatUsDate(row.original.RentalReturnDate)
                     ) ?? '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomRentalRate1Label,
            accessor: (row) => formatPrice(row.CustomRentalRate1) ?? '-',
            id: 'CustomRentalRate1',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatPrice(row.original.CustomRentalRate1) ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomRentalRate2Label,
            accessor: (row) => formatPrice(row.CustomRentalRate2) ?? '-',
            id: 'CustomRentalRate2',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatPrice(row.original.CustomRentalRate2) ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },

         {
            Header: settings.CustomRentalRate3Label,
            accessor: (row) => formatPrice(row.CustomRentalRate3) ?? '-',
            id: 'CustomRentalRate3',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatPrice(row.original.CustomRentalRate3) ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },

         {
            Header: settings.CustomRentalRate4Label,
            accessor: (row) => formatPrice(row.CustomRentalRate4) ?? '-',
            id: 'CustomRentalRate4Label',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatPrice(row.original.CustomRentalRate4) ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomDate1Label,
            accessor: (row) =>
               removeMinYear(formatUsDate(row.CustomDate1Value)) ?? '-',
            id: 'CustomDate1Value',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(
                        formatUsDate(row.original.CustomDate1Value)
                     ) ?? '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomDate2Label,
            accessor: (row) =>
               removeMinYear(formatUsDate(row.CustomDate2Value)) ?? '-',
            id: 'CustomDate2Value',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(
                        formatUsDate(row.original.CustomDate2Value)
                     ) ?? '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomText1Label,
            accessor: (row) => row.CustomText1Value ?? '-',
            id: 'CustomText1Value',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.CustomText1Value ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomText2Label,
            accessor: (row) => row.CustomText2Value ?? '-',
            id: 'CustomText2Value',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.CustomText2Value ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomURL1Label,
            accessor: (row) => row.CustomURL1Value ?? '-',
            id: 'CustomURL1Value',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     (
                        <a
                           href={row.original.CustomURL1Value}
                           target={'_blank'}
                        >
                           {row.original.CustomURL1Value}
                        </a>
                     ) ?? '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: settings.CustomURL2Label,
            accessor: (row) => row.CustomURL2Value ?? '-',
            id: 'CustomURL2Value',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     <a href={row.original.CustomURL2Value} target={'_blank'}>
                        {row.original.CustomURL2Value}
                     </a>
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'WebSite URL',
            accessor: (row) => row.WebSiteURL ?? '-',
            id: 'WebSiteURL',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     <a href={row.original.WebSiteURL} target={'_blank'}>
                        {row.original.WebSiteURL}
                     </a>
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Owners URL',
            accessor: (row) => row.AttachmentUrl ?? '-',
            id: 'AttachmentUrl',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     <a href={row.original.AttachmentUrl} target={'_blank'}>
                        {row.original.AttachmentUrl}
                     </a>
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Return Warehouse',
            accessor: (row) => row.DefaultLocation ?? '-',
            id: 'DefaultLocation',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.DefaultLocation ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Current/Pending Location',
            accessor: (row) => row.CurrentLocation ?? '-',
            id: 'CurrentLocation',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.CurrentLocation ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Last GPS Scan',
            accessor: (row) => formatLocaleDate(row.GPS?.CreatedOn) ?? '-',
            id: 'GpsCreatedOn',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatLocaleDate(row.original.GPS?.CreatedOn) ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Last Scanned By',
            accessor: (row) => row.GPS?.CreatedByName ?? '-',
            id: 'GpsCreatedByName',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.GPS?.CreatedByName ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Last Audit Date',
            accessor: (row) =>
               removeMinYear(formatUsDate(row.LastAuditDate)) ?? '-',
            id: 'LastAuditDate',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(formatUsDate(row.original.LastAuditDate)) ??
                     '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Last Audit Condition',
            accessor: (row) => row.LastAuditConditionDesc ?? '-',
            id: 'LastAuditConditionDesc',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.LastAuditConditionDesc ?? '-'}
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
         {
            Header: 'Created Date',
            accessor: (row) =>
               removeMinYear(formatUsDate(row.CreatedDate)) ?? '-',
            id: 'CreatedDate',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={
                     removeMinYear(formatUsDate(row.original.CreatedDate)) ??
                     '-'
                  }
                  disabled={isTableCellDisabled(
                     row.original.localUniqueId,
                     row.cells[0].column.Header
                  )}
               />
            ),
         },
      ],
      [settings, actionItems, selectedItemIdsInCart]
   )

   const cartColumns = useMemo(() => {
      const cartCTAColumn = {
         Header: 'Action',
         accessor: 'localUniqueId',
         id: 'CTA',
         width: 60,
         minWidth: 60,
         align: 'center',
         Cell: (cell) => (
            <CrudActionCartButtons
               cell={cell}
               action={handleCartAction}
               editAction={
                  (cell.row.original.ToolType == ToolTypes['Consumable'] ||
                     cell.row.original.ToolType ===
                        ToolTypes['Quantity Item']) &&
                  cell.row.original.StatusQuantity > 1 &&
                  selectedActionQuickFilter.label !== 'Warehouse Transfer'
               }
               deleteAction={cell.row.original.IsKitItem === false}
            />
         ),
      }
      const temp = columns.slice(1, columns.length)

      temp.unshift(cartCTAColumn)
      return temp
   }, [selectedItemsForCart])

   function generateColumnItemOptions(
      defaultColList: string[],
      columns: any[]
   ): ReorderItem[] {
      // remove cta col from reorder options we always want call to action first column
      defaultColList = defaultColList.filter((x) => x !== 'CTA')

      return defaultColList.map((colId) => {
         const tableCol = columns.find((col) => col?.id === colId)
         return {
            value: tableCol?.id,
            label: tableCol?.Header,
         }
      })
   }

   const orderedColumns = useMemo(() => {
      const columnList = actionTableOptions?.length
         ? actionTableOptions
         : Object.assign([], defaultColumnOrder)
      // CTA buttons added to default column order to render but will be removed in generateColumnItemOptions
      // so as not to be available in column reorder options
      if (!columnList.includes('CTA')) {
         columnList.unshift('CTA')
      }
      return renderOrderedTableColumns(columns, columnList)
   }, [actionTableOptions, actionItems, columns])

   const defaultColumnItems = useMemo(
      () => generateColumnItemOptions(defaultColumnOrder, columns),
      [settings]
   )
   const currentColumnItems = useMemo(
      () =>
         generateColumnItemOptions(
            actionTableOptions ? actionTableOptions : defaultColumnOrder,
            columns
         ),
      [settings]
   )

   useEffect(() => {
      setSelectedActionQuickFilter(quickFilterTabs[0])
   }, [isEmployeeUser])

   useEffect(() => {
      handleGetCartPagedItems(null, currentCartSkip, currentCartTake, null)
   }, [selectedItemsForCart])

   useEffect(() => {
      setToolSearchOptions({
         skip: PAGINATION.skip,
         take: pageSized,
         query: searchCriteria,
         ...filterValues,
         toolStatus: selectedActionQuickFilter?.status, // apply last to override toolStatus in filter values look at removing this when done
      })
   }, [selectedActionQuickFilter])

   useEffect(() => {
      if (currentSelectedItem) {
         // if current selected item and standard just add to cart
         setSelectedItemsForCart([...selectedItemsForCart, currentSelectedItem])
         setCurrentSelectedItem(null)
      }
   }, [currentSelectedItem])

   useEffect(() => {
      if (currentSelectedItemToRemove) {
         setSelectedItemsForCart(
            selectedItemsForCart.filter(
               (x) =>
                  x.localUniqueId !== currentSelectedItemToRemove.localUniqueId
            )
         )
         setCurrentSelectedItemToRemove(null)
      }
   }, [currentSelectedItemToRemove])

   useEffect(() => {
      //@ts-ignore
      if (listViewData?.data?.Data && isListSuccess && !isListLoading) {
         //@ts-ignore
         const itemData = listViewData?.data?.Data
         setItemForm(itemData)
         setIsItemDetailsVisible(true)
      }
   }, [listViewData, isListSuccess])

   const handleSearchQuery = (query?: string, skip?: number, take?: number) => {
      setIsPaginationReset(true)
      if (query) {
         setSearchCriteria(query)
      }
      setToolSearchOptions({
         skip,
         take,
         query,
         ...filterValues,
      })
   }

   // add to cart
   const handleAddOrRemoveFromCart = (selectedItemId: number) => {
      const selectedItem = actionItems.find(
         (x) => x.localUniqueId === selectedItemId
      )
      // if this item has already been added to cart remove the item
      if (selectedItemIdsInCart.includes(selectedItem.localUniqueId)) {
         setCurrentSelectedItemToRemove(selectedItem)
         return
      }
      // if not added continue with the adding to cart process
      if (isQuantityItemsOnPage([selectedItem])) {
         // @ts-ignore
         selectedItem.quantityForCart = 1 // set temp currently selected for cart on item object to 1 as newly selected
         setItemsForQuantityModal([selectedItem])
         setIsQuantityModalOpen(true) // if quantity item pass off handling to quantity modal
      } else {
         setCurrentSelectedItem(selectedItem)
      }
   }

   const isItemAlreadyAddedToCart = (selectedItemId: number) =>
      selectedItemIdsInCart.includes(selectedItemId)

   const handleAddOrRemoveFromCartFromDetailsModal = (
      selectedItemId: number
   ) => {
      handleCloseItemView()
      handleAddOrRemoveFromCart(selectedItemId)
   }

   const handleAddQuantityItemsToCart = (quantityItems: Item[]) => {
      if (
         selectedActionQuickFilter.label === 'Warehouse Transfer' &&
         selectedActionQuickFilter.status === ToolStatus.AVAILABLE
      ) {
         handleSetQuantityForCartToMax(quantityItems)
      }
      setSelectedItemsForCart([...selectedItemsForCart, ...quantityItems])
      setIsQuantityModalOpen(false)
      setItemsForQuantityModal([])
   }

   // quantity item helper methods
   const handleSetQuantityForCartToMax = (array: Item[]) => {
      array.map((x) => {
         x.quantityForCart = x.StatusQuantity
      })
   }

   const isQuantityItemsOnPage = (array: Item[]) => {
      return array.some(
         (x) =>
            (x.ToolType == ToolTypes['Consumable'] ||
               x.ToolType == ToolTypes['Quantity Item']) &&
            x.StatusQuantity > 1
      )
   }

   const addQuantityForCartPropertyToItems = (array: Item[]) => {
      array.map((x) => {
         if (
            x.ToolType == ToolTypes['Consumable'] ||
            x.ToolType == ToolTypes['Quantity Item']
         ) {
            // @ts-ignore
            x.quantityForCart = 1
         }
      })
   }

   const getQuantityItemsFromPage = (array: Item[]) => {
      return array.filter(
         (x) =>
            (x.ToolType == ToolTypes['Consumable'] ||
               x.ToolType == ToolTypes['Quantity Item']) &&
            x.StatusQuantity > 1
      )
   }
   // end quantity item helper methods

   const handleIncrementDecrementQuantity = (item: Item, val: string) => {
      const itemToAdjust = itemsForQuantityModal.find(
         (y) => y.localUniqueId === item.localUniqueId
      )
      if (val === 'dec' && itemToAdjust.quantityForCart > 1) {
         itemToAdjust.quantityForCart = itemToAdjust.quantityForCart - 1
      } else if (
         val === 'inc' &&
         itemToAdjust.quantityForCart < itemToAdjust.StatusQuantity
      ) {
         itemToAdjust.quantityForCart = itemToAdjust.quantityForCart + 1
      } else {
         return
      }
      setItemsForQuantityModal([...itemsForQuantityModal])
   }

   const handleCustomTotalChange = (e: any, item: Item) => {
      e.target.value = parseInt(e.target.value)
      const itemToAdjust = itemsForQuantityModal.find(
         (y) => y.localUniqueId === item.localUniqueId
      )
      const adjustedValue =
         e.target.value === '' ? e.target.value : Number(e.target.value)
      itemToAdjust.quantityForCart = adjustedValue
      setItemsForQuantityModal([...itemsForQuantityModal])
   }

   const handleAddAllToCart = () => {
      // filter out any items previously added to cart
      const itemsNotYetAdded = actionItems
         .filter((x) => !selectedItemIdsInCart.includes(x.localUniqueId))
         .filter((item) => item.IsKitItem === false)

      const pageHasQuantityItems = isQuantityItemsOnPage(itemsNotYetAdded)
      if (pageHasQuantityItems) {
         // if quantity items on page to add to cart hand off to quantity modal
         addQuantityForCartPropertyToItems(itemsNotYetAdded)
         setItemsForQuantityModal(itemsNotYetAdded)
         setIsQuantityModalOpen(true)
      } else {
         setSelectedItemsForCart((prevState) => [
            ...prevState,
            ...itemsNotYetAdded,
         ])
      }
   }

   const handleRemoveAllFromCart = () => {
      setSelectedItemsForCart([])
      setCurrentSelectedItem(null)
      setCurrentSelectedItemToRemove(null)
      setCurrentCartSkip(0) // reset cart pagination on empty all
   }

   const handleRemoveFromCart = (itemId: number | string) => {
      const itemToRemove = selectedItemsForCart.find(
         (x) => x.localUniqueId === Number(itemId)
      )
      setCurrentSelectedItemToRemove(itemToRemove)
   }

   const handleCartAction = (action, id?: string) => {
      if (action === Actions.Edit) {
         const itemToEdit = selectedItemsForCart.find(
            (x) => x.localUniqueId === Number(id)
         )
         setItemsForQuantityModal([itemToEdit])
         setIsEditQuantity(true)
         setIsQuantityModalOpen(true)
      } else if (action === Actions.Delete) {
         handleRemoveFromCart(id)
      } else {
         return
      }
   }

   const handleCloseQuantitiesModal = () => {
      setItemsForQuantityModal([])
      setIsQuantityModalOpen(false)
      setIsEditQuantity(false)
   }

   // get  items
   const handleGetItems = (query?: string, skip?: number, take?: number) => {
      setCurrentTake(take)
      setToolSearchOptions({
         skip: skip,
         take: take,
         query: query,
         toolStatus: selectedActionQuickFilter?.status, // only applied if no toolStatus in filters
         ...filterValues,
      })
   }

   // get cart paged items
   const handleGetCartPagedItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      setCurrentCartSkip(skip)
      setCurrentCartTake(take)
      const pagedData = selectedItemsForCart.slice(skip, take + skip)
      const ids = selectedItemsForCart.map((x) => x.localUniqueId)
      setPagedCartData(pagedData)
      setSelectedItemIdsInCart(ids)
   }

   // column order methods
   const handleResetColumns = () => {
      saveTableColumnOptions([])
      setIsColumnSettingsOpen(false)
   }

   const handleSaveColumnReorder = () => {
      setIsColumnSettingsOpen(false)
      saveTableColumnOptions(tmpColumnList)
   }

   const handleReorderColumns = (newColumns) => {
      setTmpColumnList(newColumns)
   }

   // filter methods
   const applySavedFilters = (
      filters: FiltersObject,
      tmpFilterValueWithSuffix?: any
   ) => {
      setIsFilterPanelOpen(false)
      setToolSearchOptions({
         skip: null,
         take: currentTake,
         query: searchCriteria,
         toolStatus: selectedActionQuickFilter?.status, // only applied if no toolStatus in filters
         ...filters,
      })
      setFilterValues(filters)
      setIsPaginationReset(true)
      // this is nothing more than a hack to align the tool status with duplicate values with the quick filters radio buttons
      if (tmpFilterValueWithSuffix) {
         const quickFilterIndex = Number(tmpFilterValueWithSuffix.split('_')[1])
         setSelectedActionQuickFilter(quickFilterTabs[quickFilterIndex])
      }
   }

   const handlePageCancelAndReset = () => {
      setToolSearchOptions({
         skip: PAGINATION.skip,
         take: null,
         query: '',
         toolStatus: selectedActionQuickFilter?.status, // only pass currently selected tool status as filter
      })
      setIsPageReset(true)
      setIsPaginationReset(true)
      setSearchCriteria('')
      setFilterValues({})
      handleGetItems(null, PAGINATION.skip, currentTake)
      handleRemoveAllFromCart()
   }

   const handleQuickFilterChange = (item: any) => {
      setSelectedActionQuickFilter(item)
      handleRemoveAllFromCart()
      setIsPaginationReset(true)
      handleGetItems(null, PAGINATION.skip, currentTake)
      applySavedFilters({ ...filterValues, toolStatus: item.status })
   }

   const handleCartExportToExcel = () => {
      const excelData = generateExcelData(selectedItemsForCart, settings)
      exportToExcel(excelData)
   }

   const getItemToView = (
      Id: number,
      ToolLoanId: number | unknown = null,
      KitId: number,
      e?: any,
      isCart?: boolean
   ) => {
      if (e.target.className === 'CTA' || e.target.closest('.CTA')) {
         return // don't open item card if clicking on cta
      }
      if (isCart) setIsItemDetailsVisibleCartView(true)
      getItemView({ Id, ToolLoanId, KitId })
      const stringId = String(Id)
      getItemsNotes(stringId)
      itemHistoryList(stringId)
   }

   const handleCloseItemView = () => {
      setIsItemDetailsVisible(false)
      setIsItemDetailsVisibleCartView(false)
      setItemImages([])
      resetRef.current.click()
   }

   const quantityInCart = useMemo(() => {
      return selectedItemsForCart
         .map((x) => x.quantityForCart ?? x.StatusQuantity)
         .reduce((sum, num) => sum + num, 0)
   }, [selectedItemsForCart])

   const resetRef = useRef<HTMLInputElement>(null) // used to reset the crud items radio buttons need to find another way given time to refactor items page and crud items

   return (
      <>
         <PageHeader title="Actions">
            <></>
         </PageHeader>
         <p style={{ marginTop: '1.5rem' }}>
            Select the Action you want to take. We'll only show the items
            relevant to your selection:
         </p>
         {quickFilterTabs && (
            <RadioButtonContainer
               variant="filter"
               maxWidth={isEmployeeUser && '600px'}
            >
               {quickFilterTabs.map((item, index) => {
                  return (
                     <RadioButton
                        key={`radioIcon-${index}`}
                        id={`radioIcon-${index}`}
                        groupName="radioGroup"
                        variant="filter"
                        checked={item.label === selectedActionQuickFilter.label}
                        action={() => handleQuickFilterChange(item)}
                        style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           height: '100%',
                        }}
                     >
                        <span>
                           <Svg id={item.icon} />
                        </span>
                        {item.label}
                     </RadioButton>
                  )
               })}
            </RadioButtonContainer>
         )}
         <div className={searchStyles.searchWrapper}>
            <div className={searchStyles.searchWrapperSearch}>
               <Search
                  handleQuery={handleSearchQuery}
                  searchMode={isSearchMode}
                  setSearchMode={setIsSearchMode}
                  pageSized={pageSized}
                  placeHolder={' items'}
                  setSearchCriteria={setSearchCriteria}
                  isPageSearchReset={isPageReset}
                  setIsPageSearchReset={setIsPageReset}
               />
            </div>
            <span className={searchStyles.searchWrapperFilter}>
               <ColumnsButton
                  onClick={() => setIsColumnSettingsOpen(!isColumnSettingsOpen)}
               />
            </span>
            <span className={searchStyles.searchWrapperFilter}>
               <FilterButton
                  onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
                  isActive={true}
               />
            </span>
            {!isMobile && (
               <>
                  <span className={searchStyles.searchWrapperFilter}>
                     <Button
                        variant={'primary'}
                        onClick={() => setIsCheckoutModalOpen(true)}
                        disabled={!selectedItemsForCart?.length}
                        type="button"
                        minWidth="13.25rem"
                     >
                        Check Out
                     </Button>
                  </span>
                  <span className={searchStyles.searchWrapperFilter}>
                     <Button
                        variant={'tertiary'}
                        onClick={handlePageCancelAndReset}
                        type="button"
                        minWidth="13.25rem"
                     >
                        Cancel
                     </Button>
                  </span>
               </>
            )}
         </div>
         {isMobile && ( // when switching to small viewport display checkout and cancel outside of wrapper as rows
            <>
               <Row style={{ marginBottom: '.5rem' }}>
                  <Button
                     minWidth="100%"
                     variant={'primary'}
                     onClick={() => setIsCheckoutModalOpen(true)}
                     disabled={!selectedItemsForCart?.length}
                     type="button"
                  >
                     Check Out
                  </Button>
               </Row>
               <Row>
                  <Button
                     minWidth="100%"
                     variant={'tertiary'}
                     onClick={handlePageCancelAndReset}
                     type="button"
                  >
                     Cancel
                  </Button>
               </Row>
            </>
         )}
         {isLoadingData && <LoadingOverlay />}

         <section className={actionsPageStyles.tableSection}>
            <Row gutterWidth={20}>
               <Col sm={12} md={12} lg={8.5}>
                  <Row>
                     <div className={actionsPageStyles.itemTableHeader}>
                        <Svg id="selectItems" />
                        <div style={{ marginLeft: '0.25rem' }}>
                           Select Items
                        </div>
                     </div>
                  </Row>
                  <div
                     className={actionsPageStyles.itemSelectionTableTopSection}
                  >
                     <AddAllToCartButton
                        onClick={handleAddAllToCart}
                        text={'Add All'}
                     />
                     <span style={{ marginRight: '.5rem' }}>
                        {`${actionItemsCount} Results`}
                     </span>
                  </div>
                  {!actionItemsIsLoading && (
                     <TableWrapper
                        columns={orderedColumns}
                        data={actionItems as []}
                        getItems={handleGetItems}
                        skipItems={PAGINATION.skip}
                        takeItems={PAGINATION.take}
                        totalCount={actionItemsCount}
                        extraClassName=""
                        isLoading={actionItemsIsLoading}
                        isPageReset={isPaginationReset}
                        setIsPageReset={setIsPaginationReset}
                        searchCriteria={searchCriteria}
                        searchMode
                        setItemId={(_id) => {}}
                        getItem={getItemToView}
                        setPageSized={setPageSized}
                        setSearchMode={() => null}
                        hideResultsTitle
                        isScrollView={true}
                     />
                  )}
               </Col>
               <Col sm={12} md={12} lg={3.5}>
                  <Row>
                     <div className={actionsPageStyles.itemTableHeader}>
                        <div className={actionsPageStyles.cartTitle}>
                           <Svg id="cart" />
                           <div
                              style={{ marginLeft: '0.25rem' }}
                           >{`Your Cart to ${selectedActionQuickFilter.label}`}</div>
                           <div className={actionsPageStyles.cartTotalsBadge}>
                              <span>{`${quantityInCart} ${
                                 quantityInCart === 1 ? 'Item' : 'Items'
                              }`}</span>
                           </div>
                        </div>
                     </div>
                  </Row>
                  <div
                     className={actionsPageStyles.itemSelectionTableTopSection}
                  >
                     <CartButton
                        onClick={handleCartExportToExcel}
                        text={'Export to Excel'}
                        iconId="export-row"
                        disabled={!selectedItemsForCart.length}
                     />
                     <CartButton
                        onClick={handleRemoveAllFromCart}
                        text={'Empty Cart'}
                        iconId="delete"
                     />
                  </div>
                  <TableWrapper
                     columns={cartColumns}
                     data={pagedCartData as []}
                     getItems={handleGetCartPagedItems}
                     skipItems={PAGINATION.skip}
                     takeItems={PAGINATION.take}
                     totalCount={selectedItemsForCart?.length}
                     extraClassName=""
                     isLoading={actionItemsIsLoading}
                     isPageReset={false}
                     searchCriteria=""
                     searchMode
                     setItemId={(_id) => {}}
                     getItem={getItemToView}
                     setPageSized={setPageSized}
                     setSearchMode={() => null}
                     hideResultsTitle
                     isScrollView={true}
                     isCart={true}
                  />
               </Col>
            </Row>
         </section>
         {!tableColumnOptionsLoading && (
            <ItemReorderPanel
               isColumnSettingsOpen={isColumnSettingsOpen}
               setIsColumnSettingsOpen={setIsColumnSettingsOpen}
               handleResetColumns={handleResetColumns}
               handleSaveColumnReorder={handleSaveColumnReorder}
               handleReorderColumns={handleReorderColumns}
               defaultColumnItems={defaultColumnItems}
               currentColumnItems={currentColumnItems}
               tableOptions={actionTableOptions}
            />
         )}
         <ActionsFilterPanel
            isFilterPanelOpen={isFilterPanelOpen}
            handleClose={() => setIsFilterPanelOpen(false)}
            applySavedFilters={applySavedFilters}
            isPageFilterReset={isPageReset}
            setIsPageFilterReset={setIsPageReset}
            isEmployeeUser={isEmployeeUser}
            selectedActionQuickFilter={selectedActionQuickFilter}
         />

         {isQuantityModalOpen && (
            <QuantityModal
               isModalOpen={isQuantityModalOpen}
               handleClose={handleCloseQuantitiesModal}
               handleAdjustTotal={handleIncrementDecrementQuantity}
               handleCustomTotalChange={handleCustomTotalChange}
               handleAddQuantityItemsToCart={handleAddQuantityItemsToCart}
               getQuantityItemsFromPage={getQuantityItemsFromPage}
               items={itemsForQuantityModal}
               isEdit={isEditQuantity}
               selectedActionQuickFilter={selectedActionQuickFilter}
            />
         )}
         {isCheckoutModalOpen && (
            <CheckoutModal
               isModalOpen={isCheckoutModalOpen}
               handleClose={() => setIsCheckoutModalOpen(false)}
               checkoutAction={selectedActionQuickFilter}
               selectedItems={selectedItemsForCart}
               handlePageCancelAndReset={handlePageCancelAndReset}
               isAdminCompanyUser={isAdminCompanyUser}
               quantityInCart={quantityInCart}
            />
         )}
         {isItemDetailsVisible && (
            <Modal
               isModalVisible={isItemDetailsVisible}
               closeModal={handleCloseItemView}
               // @ts-ignore
               title={itemForm?.Title}
            >
               {/* @ts-ignore */}
               <CrudItems
                  handleAddOrRemoveFromCart={
                     handleAddOrRemoveFromCartFromDetailsModal
                  }
                  isItemAlreadyAddedToCart={isItemAlreadyAddedToCart}
                  setItemImages={setItemImages}
                  itemImages={itemImages}
                  setItemStatus={setItemViewStatus}
                  itemStatus={itemViewStatus}
                  activeValue={itemForm}
                  itemNotesData={itemNotesData}
                  itemHistoryData={itemHistoryData}
                  closeModal={handleCloseItemView}
                  itemAction={Actions.Readonly}
                  isModalVisible={isItemDetailsVisible}
                  itemForm={itemForm}
                  setItemForm={() => null} // readonly
                  resetRef={resetRef}
                  parentPageView={CrudItemsParentView.Actions}
                  refetchNoteTableData={refetchNoteData}
               />
            </Modal>
         )}
      </>
   )
}
