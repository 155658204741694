import React from 'react'
import { BiColumns } from 'react-icons/bi'
import { Drawer, ItemReorder, SlidePanel } from '../common'

type Item = {
   value: string
   label: string
}

type ItemReorderPanelProps = {
   isColumnSettingsOpen: boolean
   setIsColumnSettingsOpen: (isOpen: boolean) => void
   handleResetColumns: () => void
   handleSaveColumnReorder: () => void
   handleReorderColumns: (newColumns: any) => void
   defaultColumnItems: Item[]
   currentColumnItems: Item[]
   tableOptions: any[]
}

const permissions = {
   AssignedToKitName: 'KitEnabled',
   Quantity: 'QuantityEnabled',
   StatusQuantity: 'QuantityEnabled',
   ReorderLevelQuantity: 'QuantityEnabled',
   CustomRentalRate1: 'CustomRentalRate1Enabled',
   CustomRentalRate2: 'CustomRentalRate2Enabled',
   CustomRentalRate3: 'CustomRentalRate3Enabled',
   CustomRentalRate4: 'CustomRentalRate4Enabled',
   CustomText1Value: 'CustomText1Enabled',
   CustomText2Value: 'CustomText2Enabled',
   CustomDate1Value: 'CustomDate1Enabled',
   CustomDate2Value: 'CustomDate2Enabled',
   CustomURL1Value: 'CustomURL1Enabled',
   CustomURL2Value: 'CustomURL2Enabled',
   DefaultLocation: 'EnableLocationTracking',
   CurrentLocation: 'EnableLocationTracking',
   LastAuditDate: 'AuditEnabled',
   LastAuditConditionDesc: 'AuditEnabled',
}

export const ItemReorderPanel = ({
   isColumnSettingsOpen,
   setIsColumnSettingsOpen,
   handleResetColumns,
   handleSaveColumnReorder,
   handleReorderColumns,
   defaultColumnItems,
   currentColumnItems,
   tableOptions,
}: ItemReorderPanelProps) => (
   <SlidePanel
      isOpen={isColumnSettingsOpen}
      onClose={() => setIsColumnSettingsOpen(false)}
   >
      <Drawer
         heading="Table Columns"
         icon={
            <BiColumns
               style={{ marginRight: '0.5rem', marginTop: '-0.5rem' }}
            />
         }
         onReset={handleResetColumns}
         onSave={handleSaveColumnReorder}
      >
         <p>
            Add or remove columns. To change the column order, drag and drop a
            field.
         </p>
         <ItemReorder
            defaultItems={defaultColumnItems}
            items={
               tableOptions?.length ? currentColumnItems : defaultColumnItems
            }
            onChange={handleReorderColumns}
            permissions={permissions}
         />
      </Drawer>
   </SlidePanel>
)
