import React from 'react';
import classNames from 'classnames';
import { BiColumns } from 'react-icons/bi'

import { Button } from '../..';

import { Props } from './columnsButton.type';
import * as styles from './columnsButton.module.scss';

const ColumnsButton: React.FC<Props> = ({ isActive = false, onClick }) => (
    <Button
        className={classNames(
            styles.columnButton,
            {
                [styles.columnButtonActive]: isActive
            }
        )}
        variant='plain'
        preserveText
        onClick={onClick}
        minWidth='auto'
    >
        <span className={classNames(
            styles.columnButtonIcon,
            {
                [styles.columnButtonIconActive]: isActive
            }
        )}>
            <BiColumns />
        </span>
        <span>Columns</span>
    </Button>
);

export default ColumnsButton;